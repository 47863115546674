import { TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { OutlineHeading } from '../../base/outline-heading/outline-heading';
import { html, unsafeStatic } from 'lit/static-html.js';
import { classMap } from 'lit/directives/class-map.js';

import {
  AllowedVariants,
  AllowedHeadingSizes,
  AllowedHeadingStyles,
} from './config';

import componentStyles from './ochsner-heading.css.lit';

/**
 * The Heading component.
 * @element ochsner-heading
 * @slot defaultSlot
 */
@customElement('ochsner-heading')
export class OchsnerHeading extends OutlineHeading {
  static styles = [componentStyles];

  /**
   * The variant to apply. Optional override to default heading color styles
   */
  @property({ type: String, reflect: true, attribute: 'variant' })
  variant: AllowedVariants = 'default';

  /**
   * The heading level size to apply. Optional override to default styles for a given level
   */
  @property({ type: String, reflect: true, attribute: 'level-size' })
  levelSize: AllowedHeadingSizes;

  /**
   * The heading level style to apply. Optional override to default styles for a given level
   */
  @property({ type: String, reflect: true, attribute: 'level-style' })
  levelStyle: AllowedHeadingStyles = 'semibold';

  /**
   * The top border style to apply.
   */
  @property({ type: Boolean })
  hasTopBorder: false;

  render(): TemplateResult {
    const classes = {
      'light': this.variant === 'light',
      'header-border--top': this.hasTopBorder,
      [`outline-text--${this.levelSize}`]: true,
      [`outline-font--${this.levelStyle}`]: true,
    };
    return html`
      <${unsafeStatic(this.level as string)} class=${classMap(classes)}>
        <slot></slot>
      </${unsafeStatic(this.level as string)}>`;
  }
}
