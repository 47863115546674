
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
/* The default is information. */
#body {

  display: block;

  border-width: 0px;

  border-left-width: 8px;

  border-style: solid;

  background-color: var(--ui-accent);

  padding: 1rem;

  color: var(--ui-accent);

  border-color: var(--ui-accent)
}
#header {

  font-size: var(--fs-lg);

  line-height: var(--lh-lg);

  font-weight: var(--fw-bold);

  text-transform: capitalize
}
:host([statusType='warning']) #body {

  background-color: var(--ui-warning);

  color: var(--ui-warning);

  border-color: var(--ui-warning)
}
:host([statusType='error']) #body {

  background-color: var(--ui-error);

  color: var(--ui-error);

  border-color: var(--ui-error)
}
:host([statusType='success']) #body {

  background-color: var(--ui-success);

  color: var(--ui-success);

  border-color: var(--ui-success)
}
`;