
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

    display: inline-block;

    cursor: pointer;

    border-width: 1.5px;

    border-color: var(--brand-blueDark);

    font-family: Montserrat, Helvetica, Arial, sans-serif
}

:host([disabled]) {

    cursor: not-allowed
}

.tab {

    pointer-events: none;

    display: flex;

    -webkit-user-select: none;

       -moz-user-select: none;

            user-select: none;

    align-items: center;

    justify-content: center;

    overflow: hidden;

    white-space: nowrap;

    padding: 1rem;

    text-align: center;

    font-family: Montserrat, Helvetica, Arial, sans-serif;

    font-size: var(--fs-base);

    line-height: var(--lh-base);

    font-weight: var(--fw-medium);

    color: var(--brand-blue)
}

.icon {

    margin-right: 0.75rem
}

.tab:hover:not(.tab--disabled) {

    color: var(--demo-lightGray)
}

.tab:focus {

    outline: 2px solid transparent;

    outline-offset: 2px
}

.tab:focus:not(.tab--disabled), .tab:focus-visible:not(.tab--disabled) {

    background-color: var(--demo-lightBlue);

    color: var(--demo-darkBlue);

    --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);

    --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);

    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.tab.tab--active:not(.tab--disabled) {

    background-color: var(--brand-blueDark);

    color: var(--neutral-white)
}

.tab.tab--disabled {

    cursor: not-allowed;

    opacity: 0.5
}
`;