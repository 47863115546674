
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
  max-width: 100vw;
  font-family: Hind, Helvetica, Arial, sans-serif;
}@media (min-width: 640px) {:host {
    max-width: 640px;
  }
}@media (min-width: 768px) {:host {
    max-width: 768px;
  }
}@media (min-width: 1024px) {:host {
    max-width: 1024px;
  }
}@media (min-width: 1280px) {:host {
    max-width: 1280px;
  }
}@media (min-width: 1440px) {:host {
    max-width: 1440px;
  }
}@media (min-width: 2180px) {:host {
    max-width: 2180px;
  }
}:host {
  padding-top: var(--outline-container-padding-y);
  padding-right: var(--outline-container-padding-x);
  padding-bottom: var(--outline-container-padding-y);
  padding-left: var(--outline-container-padding-x);
}

:host(:not([x-padding])) {
  padding-left: 0px;
  padding-right: 0px;
}

:host(:not([y-padding])) {
  padding-top: 0px;
  padding-bottom: 0px;
}

:host([full-bleed]) {
  max-width: 100%;
}

:host([container-align]), :host([container-align='center']) {
  margin-left: auto;
  margin-right: auto;
}

:host([container-align='left']) {
  margin-right: auto;
  margin-left: 0px;
}

:host([container-align='right']) {
  margin-left: auto;
  margin-right: 0px;
}
`;