import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OutlineElement } from '../../base/outline-element/outline-element';
import { customElement } from 'lit/decorators.js';
import componentStyles from './ochsner-card.css.lit';

/**
 * The OchsnerCard component
 * @element ochsner-card
 */
@customElement('ochsner-card')
export class OchsnerCard extends OutlineElement {
  static styles: CSSResultGroup = [componentStyles];

  render(): TemplateResult {
    return html`
      <div class="card">
        <slot></slot>
      </div>
    `;
  }
}
