import { LitElement, html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, property, query } from 'lit/decorators.js';
import componentStyles from './ochsner-menu-main.css.lit';
import '../../base/outline-icon/outline-icon';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import MmenuLight from './mmenu-light';

/**
 * The Ochsner Main Menu for mobile.
 *
 */
@customElement('ochsner-menu-main')
export class OchsnerMenuMain extends LitElement {
  // Set the CSS styles for the component.
  static styles: CSSResultGroup = [componentStyles];

  @query('#menu')
  _menu: HTMLElement;

  mmenu: typeof MmenuLight;
  navigator: typeof MmenuLight;
  drawer: typeof MmenuLight;
  menuClosed: CustomEvent<{ message: string }>;

  /**
   * Bool whether menu is open.
   * Partially Controlled by parent header component.
   */
  @property({ type: Boolean })
  isOpen = false;

  constructor() {
    super();
    this.menuClosed = new CustomEvent('menu-closed', {
      bubbles: true,
      composed: true,
    });
  }

  render(): TemplateResult {
    return html`
      <nav id="menu" tabindex="0">
        <ul>
          <li><a href="/">
            <outline-icon
              class="icon--house"
              name="house"
              size="26"
              mobile-size="26"
              alt="My Ochsner Home"
              ></outline-icon>Home</a>
          </li>
          <li><button aria-expanded="false" aria-label="Your MyOchsner Account Submenu">Your MyOchsner Account</button>
            <ul>
              <li><a href="epicmychart://openlogin?orgID=796">Log in to MyOchsner</a></li>
              <li><a href="https://my.ochsner.org/prd/accesscheck.asp?mobile=1&lang=en-US">Create an account</a></li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Get Care Submenu">Get care</button>
            <ul>
              <li><a href="/how-to-schedule-an-appointment">How to schedule an appointment</a></li>
              <li><a href="/meet-our-physicians-and-providers">Our physicians and providers</a></li>
              <li><a href="/your-care-options">Your care options</a></li>
              <li><a href="/find-urgent-care">Find urgent care now</a></li>
              <li><a href="/on-demand-urgent-care">On demand virtual care</a></li>
              <li><a href="/ochsner-on-call">Ochsner On Call</a></li>
              <li><a href="/health_calendar">Personal health calendar</a></li>
              <li><a href="https://research.ochsner.org/clinical-research">Clinical trials</a></li>
              <li><a href="https://www.ochsner.org/services">Specialties</a></li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Our Locations Submenu">Our locations</button>
            <ul>
              <li><a href="https://search.ochsner.org/?verticalKey=locations">Find a location</a></li>
              <li><a href="/hospital-guides">Campus maps and visitor info</a></li>
              <li><button aria-expanded="false" aria-label="Pharmacies Submenu">Pharmacies</button>
                <ul>
                  <li><a href="https://www.ochsner.org/services/pharmacy">Retail pharmacies</a></li>
                  <li><a href="https://www.ochsner.org/services/specialty-pharmacy">Specialty pharmacies</a></li>
                </ul>
              </li>
              <li><a href="https://www.ochsner.org/services/optical-shop">Vision centers</a></li>
              <li><a href="https://search.ochsner.org/?verticalKey=locations&facets=%7B%22c_locationServices%22%3A%5B%22Hearing+Solutions%22%5D%7D">Hearing solutions</a></li>
              <li><a href="https://www.ochsner.org/shop/home-medical-equipment-hme-total-health-solutions-ths">Home Medical Equipment store</a></li>
              <li><a href="https://www.ochsner.org/shop/o-bar">O Bar</a></li>
              <li><button aria-expanded="false" aria-label="On campus hotels Submenu">On campus hotels</button>
                <ul>
                  <li><a href="https://brenthouse.com/">Brent House Hotel</a></li>
                  <li><a href="https://alderhotel.com/">Alder Hotel</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Wellness services Submenu">Wellness services</button>
            <ul>
              <li><a href="/wellness-services-at-ochsner">Wellness services at Ochsner</a></li>
              <li><button aria-expanded="false" aria-label="Eat Well Submenu">Eat Well</button>
                <ul>
                  <li><a href="https://www.ochsner.org/eat-fit">Eat Fit</a></li>
                  <li><a href="https://www.ochsner.org/services/nutrition">Nutrition</a></li>
                </ul>
              </li>
              <li><button aria-expanded="false" aria-label="Fitness & performance training Submenu">Fitness & performance training</button>
                <ul>
                  <li><a href="https://www.ochsner.org/services/medical-fitness-program">Fitness</a></li>
                  <li><a href="https://www.ochsner.org/services/performance-training">Performance Training</a></li>
                </ul>
              </li>
              <li><a href="https://www.ochsner.org/services/smoking-cessation-clinic">Smoking cessation</a></li>
              <li><button aria-expanded="false" aria-label="Rest & Recover Submenu">Rest & Recover</button>
                <ul>
                  <li><a href="https://www.ochsner.org/services/sleep-disorders">Sleep</a></li>
                  <li><a href="https://www.ochsner.org/acupuncture">Acupuncture</a></li>
                  <li><a href="https://performance.ochsner.org/programs#recovery">Sports Recovery</a></li>
                </ul>
              </li>
              <li><a href="https://www.ochsner.org/services/ochsner-therapy-and-wellness">Therapy & Wellness</a></li>
              <li><a href="https://blog.ochsner.org/">To Your Health blog</a></li>
              <li><button aria-expanded="false" aria-label="Personal health programs Submenu">Personal health programs</button>
                <ul>
                  <li><a href="https://www.ochsner.org/services/concierge-health">Concierge Health</a></li>
                  <li><a href="https://www.ochsner.org/employers/corporate-wellness/executive-health">Executive Health</a></li>
                </ul>
              </li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Connected care programs Submenu">Connected care programs</button>
            <ul>
              <li><a href="/connected-care-programs-at-ochsner">Connected care programs at Ochsner</a></li>
              <li><a href="https://www.ochsner.org/digital-medicine">Digital Medicine</a></li>
              <li><a href="https://connectedliving.ochsner.org/">Connected Living</a></li>
              <li><a href="https://www.ochsner.org/connected-mom">Connected MOM</a></li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Billing and insurance Submenu">Billing and insurance</button>
            <ul>
              <li><a href="/pay-without-logging-in">Pay a bill</a></li>
              <li><a href="https://www.ochsner.org/patients-visitors/billing-and-financial-services/estimates#serviceTabContent">Get an estimate</a></li>
              <li><a href="https://www.ochsner.org/patients-visitors/billing-and-financial-services/billing-faqs#serviceTabContent">Billing FAQ</a></li>
              <li><a href="https://www.ochsnerhealthplan.com/">Ochsner Health Plan</a></li>
              <li><a href="/insurance-portals">Insurance portals</a></li>
            </ul>
          </li>
          <li><button aria-expanded="false" aria-label="Help and support Submenu">Help and support</button>
            <ul>
              <li><a href="/frequently-asked-questions">Frequently asked questions</a></li>
              <li><a href="/what-you-can-do-in-the-app">What you can do in the app</a></li>
              <li><a href="/live-chat-support">Live chat hours and info</a></li>
              <li><a href="https://www.ochsner.org/patients-visitors/patient-services/language-and-translation-services">Language and translation services</a></li>
              <li><a href="/send-us-your-feedback">Send us your feedback</a></li>
            </ul>
          </li>
        </ul>
      </nav>
    `;
  }

  firstUpdated(): void {
    this.mmenu = new MmenuLight(this._menu, 'all');
    this.navigator = this.mmenu.navigation({});
    this.drawer = this.mmenu.offcanvas({});

    // Listen for any click event on the menu.
    document.querySelector('.mm-ocd')?.addEventListener('click', e => {
      const path = e.composedPath();
      const targetElement = path[0] as HTMLElement;

      // If the 'mm-ocd--open' class is missing, the menu closed itself.
      const menuWrapper = targetElement.closest('.mm-ocd');
      if (!menuWrapper?.classList.contains('mm-ocd--open')) {
        // Update the state of the component to reflect closed menu.
        this.isOpen = false;
      }

      // If a link is clicked, force the menu to close and reset to the top level.
      if (targetElement.tagName == 'A') {
        this.isOpen = false;
      }
    });

    // Listen for esc key event to close the menu.
    document.addEventListener(
      'keydown',
      evnt => {
        if (this.isOpen) {
          if (evnt.key === 'Escape') {
            this.isOpen = false;
          }
          const target = evnt.target as HTMLElement;
          const targetItem = target.closest('li');
          const navFirstItem = target.closest('nav>ul')?.firstElementChild;
          const navLastMainItem = target.closest('nav>ul')?.lastElementChild;
          const currentMenuLastItem =
            targetItem?.parentElement?.lastElementChild;

          const header = document.querySelector(
            'ochsner-header'
          ) as HTMLElement;
          const menuButton = header.shadowRoot?.querySelector(
            '#menu-button'
          ) as HTMLElement;

          if (evnt.key === 'Tab') {
            if (evnt.shiftKey) {
              // If `tab+shift` from the first menu item close the menu
              if (targetItem === navFirstItem) {
                this.isOpen = false;
                menuButton?.focus();
              }
            } else {
              // If `tab` from the main-menu last item close the menu
              if (targetItem === navLastMainItem) {
                this.isOpen = false;
                menuButton?.focus();
              }
              // If `tab` while the last main-menu submenu is open and is submenu last item
              const lastMainItemOpen =
                navLastMainItem?.querySelector('ul')?.getAttribute('class') ==
                'mm-spn--open';
              if (lastMainItemOpen && currentMenuLastItem == targetItem) {
                this.isOpen = false;
                menuButton?.focus();
              }
            }
          }
        }
      },
      { passive: true }
    );
  }

  updated(): void {
    if (this.isOpen) {
      this.drawer.open();
    } else {
      this.drawer.close();
      const menu = document.querySelector('.mm-ocd .mm-spn--navbar');
      menu
        ?.querySelectorAll('.mm-spn--open')
        .forEach(n => n.classList.remove('mm-spn--open', 'mm-spn--parent'));
      menu?.classList.add('mm-spn--main');
      menu?.setAttribute('data-mm-spn-title', 'Menu');
      this.dispatchEvent(this.menuClosed);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-menu-main': OchsnerMenuMain;
  }
}
