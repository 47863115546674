import { LitElement, html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import componentStyles from './ochsner-header-nochat.css.lit';
import '../ochsner-menu-main/ochsner-menu-main';
import '../../base/outline-icon/outline-icon';

/**
 * The Ochsner Header Element.
 *
 */
@customElement('ochsner-header-nochat')
export class OchsnerHeaderNoChat extends LitElement {
  // Set the CSS styles for the component.
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Bool whether menu is open.
   */
  @state() menuOpen = false;

  connectedCallback(): void {
    super.connectedCallback();
    addEventListener('menu-closed', () => {
      this.menuOpen = false;
    });
  }

  constructor() {
    super();
  }

  render(): TemplateResult {
    const menuOpen = this.menuOpen ? 'is-open' : '';
    const menuText = this.menuOpen ? 'Close' : 'Menu';

    return html`
      <header>
        <button
          id="menu-button"
          @click=${this.setMenuActive}
          class="hamburger hamburger--slider ${menuOpen}"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
          <span class="header-text"> ${menuText} </span>
        </button>
        <a href="/" class="header-logo">
          <outline-icon
            name="my-ochsner-logo"
            size="185"
            mobile-size="185"
            alt="My Ochsner Home"
          ></outline-icon>
          <span class="hidden-text">My Ochsner Home</span>
        </a>
        <button class="chat">&nbsp;&nbsp;&nbsp;&nbsp;         
        </button>
        </header>
        <slot name="chat" class="chat-slot"></slot>
        <ochsner-menu-main .isOpen=${this.menuOpen}></ochsner-menu-main>    
    `;
  }

  /**
   * Takes the element id of content <div>
   * to maintain state list of active/open panels.
   */
  setMenuActive() {
    this.menuOpen = !this.menuOpen;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-header-nochat': OchsnerHeaderNoChat;
  }
  interface Window {
    GuideSDK:
      | {
          bot: {
            style: {
              add: Function;
            };
            openWindow: Function;
            closeWindow: Function;
          };
          on: Function;
        }
      | undefined;
    GuideSDKInit?: Function;
  }
}
