
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.dropdown {
  margin-left: 1.75rem;
  width: 10rem;
  height: 30px;
}

.icon-container {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-width: 2px;
  border-color: var(--neutral-grayLight);
  padding: 2rem;
}

.icon {
  display: flex;
  width: 5rem;
  flex-direction: column;
  align-items: center;
}

label {
  margin-left: 1.75rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  color: var(--demo-darkGray);
}

label.alt {
  color: var(--neutral-white);
}

.main {
  border-radius: 0.25rem;
  padding: 2rem;
}

.main.alt {
  background-color: var(--brand-blueDark);
}

outline-icon.alt {
  color: var(--neutral-white);
}

.search {
  margin-left: 3.5rem;
  height: 1.5rem;
  width: 15rem;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
`;