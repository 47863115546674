
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

  display: block;

  font-family: Montserrat, Helvetica, Arial, sans-serif
}

/* Devices with mouse support. */

@media (any-hover: hover) and (any-pointer: fine) {
  :host {

    cursor: pointer
  }
}

.card--vertical {

  display: flex;

  height: 100%;

  cursor: pointer;

  flex-direction: column;

  align-items: center;

  align-self: stretch;

  border-radius: 0.5rem;

  background-color: var(--neutral-white);

  padding: 1rem;

  --tw-shadow: 0px 2px 5px 0px #00000026;

  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--vertical:focus-within {

  background-color: var(--brand-blueLight-10);

  --tw-shadow: 0 0 0 1px rgb(33, 74, 222);

  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--vertical a {

  margin-top: 1.25rem
}

.card--vertical a {

  display: flex
}

.card--vertical a {

  width: 100%
}

.card--vertical a {

  justify-content: center
}

.card--vertical a {

  border-width: 0px
}

.card--vertical a {

  border-top-width: 2px
}

.card--vertical a {

  border-style: solid
}

.card--vertical a {

  border-top-color: var(--neutral-grayMedium)
}

.card--vertical a {

  padding-top: 0.5rem
}

.card--vertical a {

  text-align: center
}

.card--vertical a {

  font-size: var(--fs-base);

  line-height: var(--lh-base)
}

.card--vertical a {

  font-weight: var(--fw-semibold)
}

.card--vertical a {

  color: var(--brand-blueDark)
}

.card--vertical a {

  text-decoration: none
}

.card--vertical a:focus {

  --tw-shadow: 0 0 #0000;

  --tw-shadow-colored: 0 0 #0000;

  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);

  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)
}

.card--vertical a:focus {

  outline: 2px solid transparent;

  outline-offset: 2px
}
`;