import { CSSResultGroup, TemplateResult, html } from 'lit';
import { OchsnerCard } from '../ochsner-card/ochsner-card';
import { customElement, property, state } from 'lit/decorators.js';
import { classMap } from 'lit/directives/class-map.js';
import componentStyles from './ochsner-content-card.css.lit';
import { LinkedBlockController } from '../../controllers/linked-block-controller';
import '../../base/outline-image/outline-image';

/**
 * The OchsnerContentCard component
 * @element ochsner-content-card
 * @slot image - The optional image for the card
 * @slot summary - The summary text for the card
 */
@customElement('ochsner-content-card')
export class OchsnerContentCard extends OchsnerCard {
  static styles: CSSResultGroup = [componentStyles];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // We are using the controller to manage link behavior, but don't need to use it otherwise.
  private _linkedBlockController = new LinkedBlockController(
    this,
    () => this.shadowRoot?.querySelector(`#link`) ?? undefined
  );

  /**
   * The Title text to use.
   */
  @property({ type: String, reflect: true, attribute: 'title' })
  title = 'Title Text';

  /**
   * The URL to use.
   */
  @property({ type: String, reflect: true, attribute: 'href' })
  href = '#';

  /**
   * Flag to set if card is highlighted or not
   */
  @property({ type: Boolean, reflect: true, attribute: 'is-highlighted' })
  isHighlighted: false;

  /**
   * The Link text to use.
   */
  @property({ type: String, reflect: true, attribute: 'link-text' })
  linkText = 'Link Text';

  /**
   * The image to use.
   */

  @property({ type: String, reflect: true, attribute: 'image' })
  image: String;

  @state() hasImageSlot: boolean;

  protected firstUpdated(): void {
    this.hasImageSlot = this.querySelector('[slot="image"]') !== null;
  }

  render(): TemplateResult {
    const cardClasses = {
      'card': true,
      'card--content': true,
      'card--content-highlighted': this.isHighlighted,
    };
    return html`
      <article class="${classMap(cardClasses)}">
        ${this.imageTemplate()}
        <div class="card--content-area"><h2 class="title">${this.title}</h2>
          <slot class="summary" name="summary"></slot>
          <a id="link" href="${this.href}">
            ${this.linkText}
            <outline-icon size="20px" mobile-size="20px" name="icon-chev-circle"/>
          </a>
        </div>
      </article>
    `;
  }

  /**
   * Template partial for the Image slot
   * returns TemplateResult | null
   */
  imageTemplate(): TemplateResult | null {
    if (!this.hasImageSlot) return null;

    return html`
      <outline-image class="card--content__image">
        <slot class="card--content__image" name="image"></slot>
      </outline-image>`;
  }
}
