import { LitElement, html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';
import componentStyles from './ochsner-footer.css.lit';
/**
 * The Ochsner Footer Element.
 *
 */
@customElement('ochsner-footer')
export class OchsnerFooter extends LitElement {
  // Set the CSS styles for the component.
  static styles: CSSResultGroup = [componentStyles];

  private _scrollToTop() {
    const rootElement = document.documentElement;
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render(): TemplateResult {
    return html`
      <footer>
        <outline-icon name="ochsner-health-logo" size="100%" mobile-size="100%" alt="Ochsner Health"></outline-icon>
        <ul class="footer-social-links">
          <li class="footer-social-link-item">
            <a href="https://www.facebook.com/OchsnerHealth">
              <outline-icon name="icon-facebook" size="20px" mobile-size="20px" color="white" alt="Facebook"></outline-icon>
              <span class="hidden-text">Ochsner Facebook</span>
            </a>
          </li>
          <li class="footer-social-link-item">
            <a href="https://twitter.com/OchsnerHealth">
              <outline-icon name="icon-twitter" size="20px" mobile-size="20px" color="white" alt="Twitter"></outline-icon>
              <span class="hidden-text">Ochsner Twitter</span>
            </a>
          </li>
          <li class="footer-social-link-item">
            <a href="https://www.linkedin.com/company/ochsner-health-system-/">
              <outline-icon name="icon-linkedin" size="20px" mobile-size="20px" color="white" alt="Linkedin"></outline-icon>
              <span class="hidden-text">Ochsner Linkedin</span>
            </a>
          </li>
          <li class="footer-social-link-item">
            <a href="https://www.instagram.com/ochsnerhealth/">
              <outline-icon name="icon-instagram" size="20px" mobile-size="20px" color="white" alt="Instagram"></outline-icon>
              <span class="hidden-text">Ochsner Instagram</span>
            </a>
          </li>
          <li class="footer-social-link-item">
            <a href="https://www.youtube.com/ochsner">
              <outline-icon name="icon-youtube" size="20px" mobile-size="20px" color="white" alt="Youtube"></outline-icon>
              <span class="hidden-text">Ochsner Youtube</span>
            </a>
          </li>
          <li class="footer-social-link-item">
            <a href="https://www.pinterest.com/ochsnerhealth/">
              <outline-icon name="icon-pinterest" size="20px" mobile-size="20px" color="white" alt="Pinterest"></outline-icon>
              <span class="hidden-text">Ochsner Pinterest</span>
            </a>
          </li>
        </ul>
        <hr class="footer-divider">
        <button class="footer-scroll-top-btn" @click="${this._scrollToTop}" title="Go to top">
          <span class="footer-scroll-top-icon-container">
            <outline-icon name="icon-chev-circle-up" size="24px" mobile-size="24px" color="white"></outline-icon>
          </span>
          Back to top
        </button>
      </footer>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-footer': OchsnerFooter;
  }
}
