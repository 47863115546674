
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
}

  :host([parallax]) figure {
  height: 50vh;
}

  :host([parallax]) figure {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
  }

  :host([parallax='false']) figure {
  height: auto;
}

  :host([parallax='false']) figure {
    -webkit-clip-path: inset(0 0 0 0);
            clip-path: inset(0 0 0 0);
  }

@media (min-width: 1024px) {
    :host([parallax='false']) figure {
    height: auto;
  }
}

figure {
  margin: 0px;
  display: block;
}

::slotted([slot='caption']) {
  margin-top: 0.5rem;
  font-family: Hind, Helvetica, Arial, sans-serif;
  font-size: var(--fs-sm);
  line-height: var(--lh-sm);
}

::slotted(img) {
  width: 100%;
}
`;