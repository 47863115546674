/**
 * This file is auto generated via npm run generate.resolved-tailwind
 */

export type Color =
  | 'brand-blueLight'
  | 'brand-blue'
  | 'brand-blueDark'
  | 'brand-gold'
  | 'brand-goldDark'
  | 'brand-greenSlate'
  | 'brand-emerald'
  | 'brand-orange'
  | 'brand-sherbert'
  | 'wcag-coralDark'
  | 'wcag-orangeDark'
  | 'wcag-greenDark'
  | 'wcag-greenDarker'
  | 'wcag-purpleDark'
  | 'wcag-tealDark'
  | 'wcag-magentaDark'
  | 'neutral-transparent'
  | 'neutral-white'
  | 'neutral-grayLightest'
  | 'neutral-grayLight'
  | 'neutral-grayMedium'
  | 'neutral-grayDark'
  | 'neutral-black'
  | 'ui-error'
  | 'ui-errorDark'
  | 'ui-warning'
  | 'ui-accent'
  | 'ui-success'
  | 'transparent-blueLight50'
  | 'transparent-blueLight20'
  | 'transparent-blueLight10'
  | 'transparent-blue50'
  | 'transparent-blue20'
  | 'transparent-gold50'
  | 'transparent-gold20'
  | 'transparent-greenSlate50'
  | 'transparent-greenSlate20'
  | 'transparent-emerald50'
  | 'transparent-emerald20'
  | 'transparent-orange50'
  | 'transparent-orange20'
  | 'demo-darkBlue'
  | 'demo-mediumBlue'
  | 'demo-lightBlue'
  | 'demo-darkGray'
  | 'demo-lightGray';

export const ALL_COLORS: Color[] = [
  'brand-blueLight',
  'brand-blue',
  'brand-blueDark',
  'brand-gold',
  'brand-goldDark',
  'brand-greenSlate',
  'brand-emerald',
  'brand-orange',
  'brand-sherbert',
  'wcag-coralDark',
  'wcag-orangeDark',
  'wcag-greenDark',
  'wcag-greenDarker',
  'wcag-purpleDark',
  'wcag-tealDark',
  'wcag-magentaDark',
  'neutral-transparent',
  'neutral-white',
  'neutral-grayLightest',
  'neutral-grayLight',
  'neutral-grayMedium',
  'neutral-grayDark',
  'neutral-black',
  'ui-error',
  'ui-errorDark',
  'ui-warning',
  'ui-accent',
  'ui-success',
  'transparent-blueLight50',
  'transparent-blueLight20',
  'transparent-blueLight10',
  'transparent-blue50',
  'transparent-blue20',
  'transparent-gold50',
  'transparent-gold20',
  'transparent-greenSlate50',
  'transparent-greenSlate20',
  'transparent-emerald50',
  'transparent-emerald20',
  'transparent-orange50',
  'transparent-orange20',
  'demo-darkBlue',
  'demo-mediumBlue',
  'demo-lightBlue',
  'demo-darkGray',
  'demo-lightGray',
];

export default {
  screens: {
    xs: '480px',
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    xxl: '1440px',
    xxxl: '2180px',
  },
  colors: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  fontSize: {
    'xs': ['var(--fs-xs)', { lineHeight: 'var(--lh-xs)' }],
    'sm': ['var(--fs-sm)', { lineHeight: 'var(--lh-sm)' }],
    'base': ['var(--fs-base)', { lineHeight: 'var(--lh-base)' }],
    'lg': ['var(--fs-lg)', { lineHeight: 'var(--lh-lg)' }],
    'xl': ['var(--fs-xl)', { lineHeight: 'var(--lh-xl)' }],
    '2xl': ['var(--fs-2xl)', { lineHeight: 'var(--lh-2xl)' }],
    '3xl': ['var(--fs-3xl)', { lineHeight: 'var(--lh-3xl)' }],
    '4xl': ['var(--fs-4xl)', { lineHeight: 'var(--lh-4xl)' }],
    '5xl': ['var(--fs-5xl)', { lineHeight: 'var(--lh-5xl)' }],
    '6xl': ['var(--fs-6xl)', { lineHeight: 'var(--lh-6xl)' }],
    '7xl': ['var(--fs-7xl)', { lineHeight: 'var(--lh-7xl)' }],
    '8xl': ['var(--fs-8xl)', { lineHeight: 'var(--lh-8xl)' }],
    '9xl': ['var(--fs-9xl)', { lineHeight: 'var(--lh-9lx)' }],
  },
  fontWeight: {
    thin: 'var(--fw-extralight)',
    extralight: 'var(--fw-thin)',
    light: 'var(--fw-light)',
    normal: 'var(--fw-normal)',
    medium: 'var(--fw-medium)',
    semibold: 'var(--fw-semibold)',
    bold: 'var(--fw-bold)',
    extrabold: 'var(--fw-extrabold)',
    black: 'var(--fw-black)',
  },
  columns: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    'auto': 'auto',
    '3xs': '16rem',
    '2xs': '18rem',
    'xs': '20rem',
    'sm': '24rem',
    'md': '28rem',
    'lg': '32rem',
    'xl': '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
  },
  spacing: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  animation: {
    none: 'none',
    spin: 'spin 1s linear infinite',
    ping: 'ping 1s cubic-bezier(0, 0, 0.2, 1) infinite',
    pulse: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
    bounce: 'bounce 1s infinite',
  },
  aspectRatio: { auto: 'auto', square: '1 / 1', video: '16 / 9' },
  backdropBlur: {
    '0': '0',
    'none': '0',
    'sm': '4px',
    'DEFAULT': '8px',
    'md': '12px',
    'lg': '16px',
    'xl': '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  backdropBrightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropContrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  backdropGrayscale: { '0': '0', 'DEFAULT': '100%' },
  backdropHueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  backdropInvert: { '0': '0', 'DEFAULT': '100%' },
  backdropOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backdropSaturate: {
    '0': '0',
    '50': '.5',
    '100': '1',
    '150': '1.5',
    '200': '2',
  },
  backdropSepia: { '0': '0', 'DEFAULT': '100%' },
  backgroundColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  backgroundImage: {
    'none': 'none',
    'gradient-to-t': 'linear-gradient(to top, var(--tw-gradient-stops))',
    'gradient-to-tr': 'linear-gradient(to top right, var(--tw-gradient-stops))',
    'gradient-to-r': 'linear-gradient(to right, var(--tw-gradient-stops))',
    'gradient-to-br':
      'linear-gradient(to bottom right, var(--tw-gradient-stops))',
    'gradient-to-b': 'linear-gradient(to bottom, var(--tw-gradient-stops))',
    'gradient-to-bl':
      'linear-gradient(to bottom left, var(--tw-gradient-stops))',
    'gradient-to-l': 'linear-gradient(to left, var(--tw-gradient-stops))',
    'gradient-to-tl': 'linear-gradient(to top left, var(--tw-gradient-stops))',
  },
  backgroundOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  backgroundPosition: {
    'bottom': 'bottom',
    'center': 'center',
    'left': 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    'right': 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    'top': 'top',
  },
  backgroundSize: { auto: 'auto', cover: 'cover', contain: 'contain' },
  blur: {
    '0': '0',
    'none': '0',
    'sm': '4px',
    'DEFAULT': '8px',
    'md': '12px',
    'lg': '16px',
    'xl': '24px',
    '2xl': '40px',
    '3xl': '64px',
  },
  brightness: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  borderColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
    DEFAULT: 'currentColor',
  },
  borderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  borderRadius: {
    'none': '0px',
    'sm': '0.125rem',
    'DEFAULT': '0.25rem',
    'md': '0.375rem',
    'lg': '0.5rem',
    'xl': '0.75rem',
    '2xl': '1rem',
    '3xl': '1.5rem',
    'full': '9999px',
  },
  borderWidth: {
    '0': '0px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    '15': '1.5px',
    'DEFAULT': '1px',
  },
  boxShadow: {
    'sm': '0 1px 2px 0 rgb(0 0 0 / 0.05)',
    'DEFAULT': '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    'md': '0px 5px 10px rgba(89, 89, 89, 0.15)',
    'lg': '0px 8px 32px rgba(0, 0, 0, 0.06)',
    'xl': '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
    '2xl': '0 25px 50px -12px rgb(0 0 0 / 0.25)',
    'inner': 'inset 0 2px 4px 0 rgb(0 0 0 / 0.05)',
    'none': 'none',
    'accordion': '6px 6px 15px rgba(0, 0, 0, 0.15)',
    'focus': '0 0 0 1px rgb(33, 74, 222)',
    'focus-2': '0 0 0 2px rgb(33, 74, 222)',
    'focus-error': '0 0 0 1px rgb(225, 9, 9)',
    'button': '0px 2px 5px 0px #00000026',
  },
  boxShadowColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  caretColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  accentColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
    auto: 'auto',
  },
  contrast: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '100': '1',
    '125': '1.25',
    '150': '1.5',
    '200': '2',
  },
  container: {},
  content: { none: 'none' },
  cursor: {
    'auto': 'auto',
    'default': 'default',
    'pointer': 'pointer',
    'wait': 'wait',
    'text': 'text',
    'move': 'move',
    'help': 'help',
    'not-allowed': 'not-allowed',
    'none': 'none',
    'context-menu': 'context-menu',
    'progress': 'progress',
    'cell': 'cell',
    'crosshair': 'crosshair',
    'vertical-text': 'vertical-text',
    'alias': 'alias',
    'copy': 'copy',
    'no-drop': 'no-drop',
    'grab': 'grab',
    'grabbing': 'grabbing',
    'all-scroll': 'all-scroll',
    'col-resize': 'col-resize',
    'row-resize': 'row-resize',
    'n-resize': 'n-resize',
    'e-resize': 'e-resize',
    's-resize': 's-resize',
    'w-resize': 'w-resize',
    'ne-resize': 'ne-resize',
    'nw-resize': 'nw-resize',
    'se-resize': 'se-resize',
    'sw-resize': 'sw-resize',
    'ew-resize': 'ew-resize',
    'ns-resize': 'ns-resize',
    'nesw-resize': 'nesw-resize',
    'nwse-resize': 'nwse-resize',
    'zoom-in': 'zoom-in',
    'zoom-out': 'zoom-out',
  },
  divideColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
    DEFAULT: 'currentColor',
  },
  divideOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  divideWidth: {
    '0': '0px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    '15': '1.5px',
    'DEFAULT': '1px',
  },
  dropShadow: {
    'sm': '0 1px 1px rgb(0 0 0 / 0.05)',
    'DEFAULT': ['0 1px 2px rgb(0 0 0 / 0.1)', '0 1px 1px rgb(0 0 0 / 0.06)'],
    'md': ['0 4px 3px rgb(0 0 0 / 0.07)', '0 2px 2px rgb(0 0 0 / 0.06)'],
    'lg': ['0 10px 8px rgb(0 0 0 / 0.04)', '0 4px 3px rgb(0 0 0 / 0.1)'],
    'xl': ['0 20px 13px rgb(0 0 0 / 0.03)', '0 8px 5px rgb(0 0 0 / 0.08)'],
    '2xl': '0 25px 25px rgb(0 0 0 / 0.15)',
    'none': '0 0 #0000',
  },
  fill: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  grayscale: { '0': '0', 'DEFAULT': '100%' },
  hueRotate: {
    '0': '0deg',
    '15': '15deg',
    '30': '30deg',
    '60': '60deg',
    '90': '90deg',
    '180': '180deg',
  },
  invert: { '0': '0', 'DEFAULT': '100%' },
  flex: {
    '1': '1 1 0%',
    'auto': '1 1 auto',
    'initial': '0 1 auto',
    'none': 'none',
  },
  flexBasis: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    'full': '100%',
  },
  flexGrow: { '0': '0', 'DEFAULT': '1' },
  flexShrink: { '0': '0', 'DEFAULT': '1' },
  fontFamily: {
    sans: [
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
    serif: [
      'ui-serif',
      'Georgia',
      'Cambria',
      '"Times New Roman"',
      'Times',
      'serif',
    ],
    mono: [
      'ui-monospace',
      'SFMono-Regular',
      'Menlo',
      'Monaco',
      'Consolas',
      '"Liberation Mono"',
      '"Courier New"',
      'monospace',
    ],
    display: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'],
    body: ['Hind', 'Helvetica', 'Arial', 'sans-serif'],
  },
  gap: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  gradientColorStops: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  gridAutoColumns: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridAutoRows: {
    auto: 'auto',
    min: 'min-content',
    max: 'max-content',
    fr: 'minmax(0, 1fr)',
  },
  gridColumn: {
    'auto': 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-7': 'span 7 / span 7',
    'span-8': 'span 8 / span 8',
    'span-9': 'span 9 / span 9',
    'span-10': 'span 10 / span 10',
    'span-11': 'span 11 / span 11',
    'span-12': 'span 12 / span 12',
    'span-full': '1 / -1',
  },
  gridColumnEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    'auto': 'auto',
  },
  gridColumnStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    '13': '13',
    'auto': 'auto',
  },
  gridRow: {
    'auto': 'auto',
    'span-1': 'span 1 / span 1',
    'span-2': 'span 2 / span 2',
    'span-3': 'span 3 / span 3',
    'span-4': 'span 4 / span 4',
    'span-5': 'span 5 / span 5',
    'span-6': 'span 6 / span 6',
    'span-full': '1 / -1',
  },
  gridRowStart: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    'auto': 'auto',
  },
  gridRowEnd: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    'auto': 'auto',
  },
  gridTemplateColumns: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    '7': 'repeat(7, minmax(0, 1fr))',
    '8': 'repeat(8, minmax(0, 1fr))',
    '9': 'repeat(9, minmax(0, 1fr))',
    '10': 'repeat(10, minmax(0, 1fr))',
    '11': 'repeat(11, minmax(0, 1fr))',
    '12': 'repeat(12, minmax(0, 1fr))',
    'none': 'none',
    '1-2': '4fr 8fr',
    '2-1': '8fr 4fr',
  },
  gridTemplateRows: {
    '1': 'repeat(1, minmax(0, 1fr))',
    '2': 'repeat(2, minmax(0, 1fr))',
    '3': 'repeat(3, minmax(0, 1fr))',
    '4': 'repeat(4, minmax(0, 1fr))',
    '5': 'repeat(5, minmax(0, 1fr))',
    '6': 'repeat(6, minmax(0, 1fr))',
    'none': 'none',
  },
  height: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '30': '7.5rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  inset: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    'full': '100%',
  },
  keyframes: {
    spin: { to: { transform: 'rotate(360deg)' } },
    ping: { '75%, 100%': { transform: 'scale(2)', opacity: '0' } },
    pulse: { '50%': { opacity: '.5' } },
    bounce: {
      '0%, 100%': {
        transform: 'translateY(-25%)',
        animationTimingFunction: 'cubic-bezier(0.8,0,1,1)',
      },
      '50%': {
        transform: 'none',
        animationTimingFunction: 'cubic-bezier(0,0,0.2,1)',
      },
    },
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0em',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    'none': '1',
    'tight': '1.25',
    'snug': '1.375',
    'normal': '1.5',
    'relaxed': '1.625',
    'loose': '2',
  },
  listStyleType: { none: 'none', disc: 'disc', decimal: 'decimal' },
  margin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  maxHeight: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': 'var(--spacing-56)',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  maxWidth: {
    '0': '0rem',
    'none': 'none',
    'xs': '20rem',
    'sm': '24rem',
    'md': '28rem',
    'lg': '32rem',
    'xl': '36rem',
    '2xl': '42rem',
    '3xl': '48rem',
    '4xl': '56rem',
    '5xl': '64rem',
    '6xl': '72rem',
    '7xl': '80rem',
    'full': '100%',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'prose': '65ch',
    'screen-xs': '480px',
    'screen-sm': '640px',
    'screen-md': '768px',
    'screen-lg': '1024px',
    'screen-xl': '1280px',
    'screen-xxl': '1440px',
    'screen-xxxl': '2180px',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
    'wideScreen': '35.5rem',
  },
  minHeight: {
    '0': '0px',
    'full': '100%',
    'screen': '100vh',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
  },
  minWidth: {
    '0': '0px',
    'full': '100%',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
  },
  objectPosition: {
    'bottom': 'bottom',
    'center': 'center',
    'left': 'left',
    'left-bottom': 'left bottom',
    'left-top': 'left top',
    'right': 'right',
    'right-bottom': 'right bottom',
    'right-top': 'right top',
    'top': 'top',
  },
  opacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  order: {
    '1': '1',
    '2': '2',
    '3': '3',
    '4': '4',
    '5': '5',
    '6': '6',
    '7': '7',
    '8': '8',
    '9': '9',
    '10': '10',
    '11': '11',
    '12': '12',
    'first': '-9999',
    'last': '9999',
    'none': '0',
  },
  padding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  placeholderColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  placeholderOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  outlineColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  outlineOffset: { '0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px' },
  outlineWidth: { '0': '0px', '1': '1px', '2': '2px', '4': '4px', '8': '8px' },
  ringColor: {
    DEFAULT: '#3b82f6',
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  ringOffsetColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  ringOffsetWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
  },
  ringOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
    'DEFAULT': '0.5',
  },
  ringWidth: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'DEFAULT': '3px',
  },
  rotate: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
    '45': '45deg',
    '90': '90deg',
    '180': '180deg',
  },
  saturate: { '0': '0', '50': '.5', '100': '1', '150': '1.5', '200': '2' },
  scale: {
    '0': '0',
    '50': '.5',
    '75': '.75',
    '90': '.9',
    '95': '.95',
    '100': '1',
    '105': '1.05',
    '110': '1.1',
    '125': '1.25',
    '150': '1.5',
  },
  scrollMargin: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  scrollPadding: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  sepia: { '0': '0', 'DEFAULT': '100%' },
  skew: {
    '0': '0deg',
    '1': '1deg',
    '2': '2deg',
    '3': '3deg',
    '6': '6deg',
    '12': '12deg',
  },
  space: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  stroke: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  strokeWidth: { '0': '0', '1': '1', '2': '2' },
  textColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  textDecorationColor: {
    brand: {
      blueLight: 'var(--brand-blueLight)',
      blue: 'var(--brand-blue)',
      blueDark: 'var(--brand-blueDark)',
      gold: 'var(--brand-gold)',
      goldDark: 'var(--brand-goldDark)',
      greenSlate: 'var(--brand-greenSlate)',
      emerald: 'var(--brand-emerald)',
      orange: 'var(--brand-orange)',
      sherbert: 'var(--brand-sherbert)',
    },
    wcag: {
      coralDark: 'var(--brand-coralDark)',
      orangeDark: 'var(--brand-orangeDark)',
      greenDark: 'var(--brand-greenDark)',
      greenDarker: 'var(--brand-greenDarker)',
      purpleDark: 'var(--brand-purpleDark)',
      tealDark: 'var(--brand-tealDark)',
      magentaDark: 'var(--brand-magentaDark)',
    },
    neutral: {
      transparent: 'var(--neutral-transparent)',
      white: 'var(--neutral-white)',
      grayLightest: 'var(--neutral-grayLightest)',
      grayLight: 'var(--neutral-grayLight)',
      grayMedium: 'var(--neutral-grayMedium)',
      grayDark: 'var(--neutral-grayDark)',
      black: 'var(--neutral-black)',
    },
    ui: {
      error: 'var(--ui-error)',
      errorDark: 'var(--ui-error-dark)',
      warning: 'var(--ui-warning)',
      accent: 'var(--ui-accent)',
      success: 'var(--ui-success)',
    },
    transparent: {
      blueLight50: 'var(--brand-blueLight-50)',
      blueLight20: 'var(--brand-blueLight-20)',
      blueLight10: 'var(--brand-blueLight-10)',
      blue50: 'var(--brand-blue-50)',
      blue20: 'var(--brand-blue-20)',
      gold50: 'var(--brand-gold-50)',
      gold20: 'var(--brand-gold-20)',
      greenSlate50: 'var(--brand-greenSlate-50)',
      greenSlate20: 'var(--brand-greenSlate-20)',
      emerald50: 'var(--brand-emerald-50)',
      emerald20: 'var(--brand-emerald-20)',
      orange50: 'var(--brand-orange-50)',
      orange20: 'var(--brand-orange-20)',
    },
    demo: {
      darkBlue: 'var(--demo-darkBlue)',
      mediumBlue: 'var(--demo-mediumBlue)',
      lightBlue: 'var(--demo-lightBlue)',
      darkGray: 'var(--demo-darkGray)',
      lightGray: 'var(--demo-lightGray)',
    },
  },
  textDecorationThickness: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'auto': 'auto',
    'from-font': 'from-font',
  },
  textUnderlineOffset: {
    '0': '0px',
    '1': '1px',
    '2': '2px',
    '4': '4px',
    '8': '8px',
    'auto': 'auto',
  },
  textIndent: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
  },
  textOpacity: {
    '0': '0',
    '5': '0.05',
    '10': '0.1',
    '20': '0.2',
    '25': '0.25',
    '30': '0.3',
    '40': '0.4',
    '50': '0.5',
    '60': '0.6',
    '70': '0.7',
    '75': '0.75',
    '80': '0.8',
    '90': '0.9',
    '95': '0.95',
    '100': '1',
  },
  transformOrigin: {
    'center': 'center',
    'top': 'top',
    'top-right': 'top right',
    'right': 'right',
    'bottom-right': 'bottom right',
    'bottom': 'bottom',
    'bottom-left': 'bottom left',
    'left': 'left',
    'top-left': 'top left',
  },
  transitionDelay: {
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
  },
  transitionDuration: {
    '75': '75ms',
    '100': '100ms',
    '150': '150ms',
    '200': '200ms',
    '300': '300ms',
    '500': '500ms',
    '700': '700ms',
    '1000': '1000ms',
    'DEFAULT': '150ms',
  },
  transitionProperty: {
    none: 'none',
    all: 'all',
    DEFAULT:
      'background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
    colors: 'background-color, border-color, color, fill, stroke',
    opacity: 'opacity',
    shadow: 'box-shadow',
    transform: 'transform',
  },
  transitionTimingFunction: {
    'DEFAULT': 'cubic-bezier(0.4, 0, 0.2, 1)',
    'linear': 'linear',
    'in': 'cubic-bezier(0.4, 0, 1, 1)',
    'out': 'cubic-bezier(0, 0, 0.2, 1)',
    'in-out': 'cubic-bezier(0.4, 0, 0.2, 1)',
  },
  translate: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    'full': '100%',
  },
  width: {
    '0': '0px',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
    '11': '2.75rem',
    '12': '3rem',
    '14': '3.5rem',
    '16': '4rem',
    '18': 'var(--spacing-18)',
    '20': '5rem',
    '21': 'var(--spacing-21)',
    '24': '6rem',
    '28': '7rem',
    '30': '7.5rem',
    '32': '8rem',
    '36': '9rem',
    '40': '10rem',
    '44': '11rem',
    '48': '12rem',
    '52': '13rem',
    '56': '14rem',
    '60': '15rem',
    '64': '16rem',
    '72': '18rem',
    '80': '20rem',
    '96': '24rem',
    '112': 'var(--spacing-112)',
    '128': 'var(--spacing-128)',
    '136': 'var(--spacing-136)',
    'auto': 'auto',
    'px': '1px',
    '0.5': '0.125rem',
    '1.5': '0.375rem',
    '2.5': '0.625rem',
    '3.5': '0.875rem',
    'half': '50%',
    'half-screen-x': '50vw',
    '0.75': 'var(--spacing-0_75)',
    '7.5': 'var(--spacing-7_5)',
    '1/2': '50%',
    '1/3': '33.333333%',
    '2/3': '66.666667%',
    '1/4': '25%',
    '2/4': '50%',
    '3/4': '75%',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',
    '1/6': '16.666667%',
    '2/6': '33.333333%',
    '3/6': '50%',
    '4/6': '66.666667%',
    '5/6': '83.333333%',
    '1/12': '8.333333%',
    '2/12': '16.666667%',
    '3/12': '25%',
    '4/12': '33.333333%',
    '5/12': '41.666667%',
    '6/12': '50%',
    '7/12': '58.333333%',
    '8/12': '66.666667%',
    '9/12': '75%',
    '10/12': '83.333333%',
    '11/12': '91.666667%',
    'full': '100%',
    'screen': '100vw',
    'min': 'min-content',
    'max': 'max-content',
    'fit': 'fit-content',
    'full-screen-w': '100vw',
    'half-screen-w': '50vw',
    'quarter-screen-w': '25vw',
    'full-screen-h': '100vh',
    'half-screen-h': '50vh',
    'quarter-screen-h': '25vh',
  },
  willChange: {
    auto: 'auto',
    scroll: 'scroll-position',
    contents: 'contents',
    transform: 'transform',
  },
  zIndex: {
    '0': '0',
    '10': '10',
    '20': '20',
    '30': '30',
    '40': '40',
    '50': '50',
    'auto': 'auto',
  },
  rounded: { xl: '8px' },
};
