
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
/* See https://github.com/tailwindlabs/tailwindcss-aspect-ratio/blob/master/src/index.js */

:host {
  position: relative;
  display: block;
  height: 0px;
  padding-bottom: calc(9 / 16 * 100%)
}

iframe {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: block;
  height: 100%;
  width: 100%
}
`;