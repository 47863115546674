
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  display: block;
}

.tab-group {
  display: flex;
  border-width: 1px;
  border-color: var(--neutral-transparent);
}

.tab-group .tab-group__tabs {
  position: relative;
  display: flex;
}

.tab-group .tab-group__indicator {
  position: absolute;
  left: 0px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

/*
 * Start
 */

.tab-group--start {
  flex-direction: row;
}

.tab-group--start .tab-group__nav-container {
  order: 1;
  border-width: 1px;
  border-bottom-color: var(--demo-lightGray);
}

.tab-group--start .tab-group__tabs {
  flex: 0 0 auto;
  flex-direction: column;
}

.tab-group--start .tab-group__indicator {
  right: calc(-1 * 2px);
  border-bottom: 0;
}

.tab-group--start .tab-group__body {
  order: 2;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex: 1 1 auto;
}

/*
 * Top
 */

.tab-group--top {
  flex-direction: column;
}

.tab-group--top .tab-group__nav-container {
  order: 1;
}

.tab-group--top .tab-group__nav {
  display: flex;
  overflow-x: auto;

  /* Hide scrollbar in Firefox */
  scrollbar-width: none;
}

/* Hide scrollbar in Chrome/Safari */

.tab-group--top .tab-group__nav::-webkit-scrollbar {
  height: 0px;
  width: 0px;
}

.tab-group--top .tab-group__tabs {
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  border-width: 1px;
  border-bottom-color: var(--demo-darkGray);
}

.tab-group--top .tab-group__tabs .tab {
  padding-right: 1.5rem;
  background-image: none;
}

.tab-group--top .tab-group__indicator {
  bottom: -2px;
}

.tab-group--top .tab-group__body {
  order: 2;
}
`;