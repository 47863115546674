
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.list {
  border: unset;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
}

ul.list, ul.grid {
  list-style-type: none;
}

/* basic padding. 
 Can be overridden by applying styles directly to the li */

ul.list > ::slotted(li), ol.list > ::slotted(li), ul.grid > ::slotted(li), ol.grid > ::slotted(li) {
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding: 0.5rem;
}

.columns--2,
.columns--3,
.columns--4 {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile-row {
  display: flex;
  flex-direction: row;
}

.mobile-col {
  display: flex;
  flex-direction: column;
}

/*above sm*/

ul.list.divided* > ::slotted(li:not(:last-of-type)) {
  border-bottom: 0 !important;
}

@media (min-width: 640px) {
  .mobile-col-center {
    display: flex;
    flex-direction: row;
  }

  .mobile-col {
    display: flex;
    flex-direction: row;
  }

  .mobile-row {
    display: flex;
    flex-direction: column;
  }

  .columns--2 {
    display: grid;
  }

  .columns--2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .columns--3 {
    display: grid;
  }

  .columns--3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .columns--3 {
    gap: 0.25rem;
  }

  .columns--4 {
    display: grid;
  }

  .columns--4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  :is(ul, ol, div).list.divided > ::slotted(li:not(:last-of-type))::after {
    content: '|';
    margin-left: 1rem;
  }

  :is(ul, ol, div).list.divided > ::slotted(li:not(:last-of-type))::after {
    color: white;
  }

  :is(ul, ol, div).list.divided.blue > ::slotted(li:not(:last-of-type))::after {
    color: #0080fe;
  }

  :is(ul, ol, div).list.divided.teal > ::slotted(li:not(:last-of-type))::after {
    color: #72f1e4;
  }

  :is(ul, ol, div).list.divided.red > ::slotted(li:not(:last-of-type))::after {
    color: #fa5c5c;
  }

  :is(ul, ol, div).list.divided.black
    > ::slotted(li:not(:last-of-type))::after {
    color: black;
  }
}
`;