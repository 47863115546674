import { LitElement, html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import componentStyles from './ochsner-header.css.lit';
import './../ochsner-menu-main/ochsner-menu-main';
import '../../base/outline-icon/outline-icon';

/**
 * The Ochsner Header Element.
 *
 */
@customElement('ochsner-header')
export class OchsnerHeader extends LitElement {
  // Set the CSS styles for the component.
  static styles: CSSResultGroup = [componentStyles];

  /**
   * Bool whether menu is open.
   */
  @state() menuOpen = false;

  connectedCallback(): void {
    super.connectedCallback();
    addEventListener('menu-closed', () => {
      this.menuOpen = false;
    });
  }

  /**
   * Track open/closed state of the chatbot window.
   */
  @state()
  private _isChatbotWindowOpen = false;

  constructor() {
    super();
    window.GuideSDKInit = this._handleChatbotLoaded;
  }

  render(): TemplateResult {
    const menuOpen = this.menuOpen ? 'is-open' : '';
    const menuText = this.menuOpen ? 'Close' : 'Menu';

    return html`
      <header>
        <button
          id="menu-button"
          @click=${this.setMenuActive}
          class="hamburger hamburger--slider ${menuOpen}"
        >
          <span class="hamburger-box">
            <span class="hamburger-inner"></span>
          </span>
          <span class="header-text"> ${menuText} </span>
        </button>
        <a href="/" class="header-logo">
          <outline-icon
            name="my-ochsner-logo"
            size="185"
            mobile-size="185"
            alt="My Ochsner Home"
          ></outline-icon>
          <span class="hidden-text">My Ochsner Home</span>
        </a>
        <button class="chat" @click=${this._toggleChatbot}>
          <outline-icon 
            name="icon-new-chat" 
            size="24px"
            mobile-size="24px"
          ></outline-icon>
          <span class="header-text header-text--chat">Chat</span>
        </button>
      </header>
      
      <slot name="chat" class="chat-slot"></slot>
      <ochsner-menu-main .isOpen=${this.menuOpen}></ochsner-menu-main>
    `;
  }

  /**
   * Takes the element id of content <div>
   * to maintain state list of active/open panels.
   */
  setMenuActive() {
    this.menuOpen = !this.menuOpen;
  }

  /**
   * Generate a script tag to load the Loyal Health chatbot.
   *
   * May be injected into the DOM to make the chatbot work in Storybook,
   * not currently in use.
   *
   * @returns HTMLElement - The DOM element for loading the chatbot script.
   */
  loyalhealthScript(): HTMLElement {
    const script = document.createElement('script');
    script.dataset.id = 'guide-client-id';
    script.dataset.value = '962B3D6D-AC20-4C41-B92C-BC7D83D546EC';
    script.async = true;
    script.type = 'text/javascript';
    script.src = 'https://guide.loyalhealth.com/client/client.bundle.js';
    return script;
  }

  /**
   * Function to run once the external chatbot script has loaded.
   *
   * Hides the out of the box chatbot button and ensures that chat
   * window initializes in a closed state.
   */
  private _handleChatbotLoaded(): void {
    const chatbotCssOverride = `
    [class^="LauncherPortal__LauncherPortalWrapper"] {
      display: none;
      visibility: hidden;
    }
    [class^="GuideContainer__GuideFixedWrapper"] {
      width: 100%;
    }
    [class^="GuideContainer__GuideFixedWrapper"] [class^="GuideContainer__GuideContainerWrapper"] {
      height: initial;
      min-height: initial;
    }
    #loyal-guide-container[class^="GuideContainer__GuideContainerWrapper"] [class^="ChatContainer__ChatWrapper"] {
      bottom: 0;
      z-index: 1000;
      max-height: 100vh;
      height: -webkit-fill-available;
      width: 100%;
      border-radius: 0;
      right: 0;
      position: fixed;
    }
    [class^="IntroFooter__Wrapper"] {
      clear: both;
    }
    [class^="IntroFooter__Wrapper"] [class^="IntroFooter__IntroWarning"] {
      position: static;
    }
    @media (max-width: 768px) {
      [class^="GuideThemeProvider__GlobalStyle"] [class^="GuideContainer__GuideFixedWrapper"] {
        height: initial;
      }
    }
    @media (min-height: 500px) {
      [class^="IntroFooter__Wrapper"] [class^="IntroFooter__IntroWarning"] {
        position: absolute;
      }
    }
    `;
    if (typeof window.GuideSDK !== 'undefined') {
      window.GuideSDK.bot.style.add(chatbotCssOverride);
      //Check for open chat query string and open chat bot if it exists
      const urlParams = new URLSearchParams(window.location.search);
      const showChat = urlParams.get('showChat');

      if (showChat == '1') {
        window.GuideSDK.bot.openWindow();
      } else {
        // In case chat window was left open in a previous session,
        // ensure the window is closed on initialization so that it
        // matches the _isChatbotWindowOpen state at initialization.
        window.GuideSDK.bot.closeWindow();
      }

      window.GuideSDK.on('windowOpen', () => {
        this._isChatbotWindowOpen = true;
      });
      window.GuideSDK.on('windowClose', () => {
        this._isChatbotWindowOpen = false;
      });
    }
  }

  /**
   * Toggle the chatbot window between open and closed states.
   */
  private _toggleChatbot(): void {
    if (typeof window.GuideSDK !== 'undefined') {
      if (this._isChatbotWindowOpen) {
        window.GuideSDK.bot.closeWindow();
      } else {
        window.GuideSDK.bot.openWindow();
      }
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-header': OchsnerHeader;
  }
  interface Window {
    GuideSDK:
      | {
          bot: {
            style: {
              add: Function;
            };
            openWindow: Function;
            closeWindow: Function;
          };
          on: Function;
        }
      | undefined;
    GuideSDKInit?: Function;
  }
}
