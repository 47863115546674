
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
/* Devices with mouse support. */
@media (any-hover: hover) and (any-pointer: fine) {
  :host {
    cursor: pointer;
  }
}
.card--horizontal {
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background-color: var(--neutral-white);
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--brand-blueDark);
  --tw-shadow: 0px 2px 5px 0px #00000026;
  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.card--horizontal:focus-within {
  background-color: var(--brand-blueLight-10);
  --tw-shadow: 0 0 0 1px rgb(33, 74, 222);
  --tw-shadow-colored: 0 0 0 1px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.card--horizontal a {
  display: flex;
}
.card--horizontal a {
  height: 100%;
}
.card--horizontal a {
  width: 100%;
}
.card--horizontal a {
  padding-left: 1rem;
}
.card--horizontal a {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}
.card--horizontal a {
  font-weight: var(--fw-semibold);
}
.card--horizontal a {
  color: var(--brand-blueDark);
}
.card--horizontal a {
  text-decoration: none;
}
.card--horizontal a:focus {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.card--horizontal a:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.card--horizontal .left-icon, .card--horizontal .card--horizontal__image {
  border-width: 0px;
}
.card--horizontal .left-icon, .card--horizontal .card--horizontal__image {
  border-right-width: 2px;
}
.card--horizontal .left-icon, .card--horizontal .card--horizontal__image {
  border-style: solid;
}
.card--horizontal .left-icon, .card--horizontal .card--horizontal__image {
  border-right-color: var(--neutral-grayMedium);
}
.card--horizontal .left-icon, .card--horizontal .card--horizontal__image {
  padding-right: 1rem;
}
.card--horizontal .headline {
  padding-right: 1rem;
}
.card--horizontal .card--horizontal__image:focus {
  background-color: var(--neutral-white);
}
.card--horizontal .card--horizontal__image {
    width: 31%;
    max-width: 175px;
  }
.card--horizontal .right-icon {
  padding-left: 1.25rem;
}
.card--horizontal ::slotted(img):focus {
  background-color: var(--neutral-white);
}
`;