
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.accordion-title {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  font-size: var(--fs-4xl);
  line-height: var(--lh-4xl);
  color: var(--demo-darkBlue)
}

.accordion-title.mobile {
  font-size: var(--fs-lg);
  line-height: var(--lh-lg);
  color: var(--demo-darkBlue)
}

.accordion-title:after {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  width: 2rem;
  border-width: 1px;
  border-style: solid;
  border-color: var(--demo-darkBlue);
  content: ''
}

.accordion-title.mobile:after {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  width: 1rem;
  border-width: 1px
}
`;