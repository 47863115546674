
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
.hero {
  position: relative;
  margin-left: -1rem;
  margin-right: -1rem;
}

.hero.no-image {
  margin: auto;
}

.hero::after {
  position: absolute;
  top: 0px;
  background-color: var(--brand-blueDark);
  content: '';
  height: 150px;
  width: 100vw;
  left: calc(-50vw + 50%);
}

.hero.no-image::after {
  --tw-content: none;
  content: var(--tw-content);
}

.hero.blueOverlay::after {
  height: 100%;
}

.hero-inner {
  position: relative;
  margin: auto;
}

.img-wrapper {
  position: relative;
  z-index: 10;
}

.img-wrapper::after {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, var(--light-overlay));
  content: '';
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: top;
  max-height: 375px;
}

.img-wrapper.blueOverlay::after {
  background: linear-gradient(0deg, var(--blue-overlay));
}

.btn-wrapper {
  position: absolute;
  z-index: 20;
  margin-top: 1rem;
}

.btn-wrapper .btn {
  margin-left: 1rem;
  margin-right: 1rem;
}

.btn-wrapper .btn {
  display: flex;
}

.btn-wrapper .btn {
  cursor: pointer;
}

.btn-wrapper .btn {
  align-items: center;
}

.btn-wrapper .btn {
  border-radius: 0.5rem;
}

.btn-wrapper .btn {
  border-width: 2px;
}

.btn-wrapper .btn {
  border-style: solid;
}

.btn-wrapper .btn {
  border-color: var(--neutral-white);
}

.btn-wrapper .btn {
  background-color: var(--neutral-transparent);
}

.btn-wrapper .btn {
  padding-left: 1rem;
  padding-right: 1rem;
}

.btn-wrapper .btn {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.btn-wrapper .btn {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.btn-wrapper .btn {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.btn-wrapper .btn {
  font-weight: var(--fw-bold);
}

.btn-wrapper .btn {
  color: var(--neutral-white);
}

.btn-wrapper .btn {
  text-decoration: none;
}

.icon-container {
  margin-right: 0.75rem;
  transform: rotate(90deg);
}

.heading-wrapper {
  padding-left: 1rem;
  padding-right: 1rem;
}

.heading-wrapper.no-image {
  margin-top: 2rem;
  padding-left: 0px;
  padding-right: 0px;
}

.heading-wrapper.blueOverlay {
  position: absolute;
  bottom: 0px;
  z-index: 10;
}

.heading-wrapper.blueOverlay.no-image {
  position: relative;
}

@media (min-width: 600px) {
  .hero-inner {
    max-width: 35.5rem;
  }

  .hero {
    margin: auto;
  }

  .heading-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .heading-wrapper.blueOverlay {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .btn-wrapper {
    margin-top: 2.5rem;
  }
}
`;