import { customElement, property } from 'lit/decorators.js';
import { CSSResultGroup, html, TemplateResult } from 'lit';
import componentStyles from './ochsner-alert.css.lit';
import { OutlineAlert } from '../../base/outline-alert/outline-alert';
import '../../base/outline-link/outline-link';
import { LinkedBlockController } from '../../controllers/linked-block-controller';

/**
 * The OchsnerAlert component
 * @element ochsner-alert
 * @slot default - the content for the alert
 */
@customElement('ochsner-alert')
export class OchsnerHorizontalLinkCard extends OutlineAlert {
  static styles: CSSResultGroup = [componentStyles];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // We are using the controller to manage link behavior, but don't need to use it otherwise.
  private _linkedBlockController = new LinkedBlockController(
    this,
    () => this.shadowRoot?.querySelector(`#link`) ?? undefined
  );

  /**
   * Link url
   */
  @property({ type: String, attribute: 'link-href' })
  linkHref = '#';

  /**
   * Link text
   */
  @property({ type: String, attribute: 'link-text' })
  linkText: string;

  render(): TemplateResult {
    // The `body` wrapper is used to avoid styles (like border) that are preventing us from styling `:host`.
    return html`
      <div id="body" role="alertdialog" aria-labelledby="message">
        <a id="link" href="${this.linkHref}">
          <div id="header">
            <slot name="outline-alert--header">${
              this.statusType === 'information' ? 'Info' : this.statusType
            }</slot>
          </div>
          <div id="message">
            <slot></slot>
            ${
              this.linkText &&
              html`<div class="alert__link-text">${this.linkText}</div>`
            } 
          </div>
        </a>
      </div>
    `;
  }
}
