
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {
  /* flex and border-radius constrain the actual boundaries of the element,
   preventing literal edge cases where a user can click on the edge or corner of the button and default states like disabled */
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.btn[aria-disabled='true'] {
  pointer-events: none !important;
  cursor: not-allowed !important;
  background-color: var(--demo-lightGray) !important;
  color: var(--demo-darkGray) !important;
}

.btn {
  display: flex;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.75rem;
  border-width: 0px;
  border-style: solid;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  line-height: 1.25;
  text-decoration: none;
  --tw-shadow: 0px 2px 5px 0px #00000026;
  --tw-shadow-colored: 0px 2px 5px 0px var(--tw-shadow-color);
  box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

.btn:hover {
  text-decoration: underline;
}

.btn:focus {
  text-decoration: underline;
}

.btn outline-icon {
  position: relative;
  margin-right: 0.5rem;
}

.btn.default {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: var(--brand-blue);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));
  --tw-gradient-to: var(--brand-blueDark);
  color: var(--neutral-white);
}

.btn.default:hover {
  background-color: var(--brand-blueDark);
}

.btn.default:focus {
  background-color: var(--brand-blueDark);
}

.btn.secondary {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: var(--brand-gold-50);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));
  --tw-gradient-to: var(--brand-gold);
  color: var(--brand-blueDark);
}

.btn.secondary:hover {
  background-color: var(--brand-gold);
}

.btn.secondary:focus {
  background-color: var(--brand-gold);
}

.btn.outline, .btn.white {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: var(--neutral-white);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));
  --tw-gradient-to: var(--brand-blueLight-10);
}

.btn.outline:hover, .btn.white:hover {
  background-color: var(--brand-blueLight-10);
}

.btn.outline:focus, .btn.white:focus {
  background-color: var(--brand-blueLight-10);
}

.btn.outline {
  border-width: 2px;
  border-color: var(--ui-accent);
  color: var(--ui-accent);
}

.btn.white {
  border-top-color: var(--neutral-white);
  color: var(--brand-blueDark);
}

.small {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-semibold);
}

.large {
  padding-left: 2rem;
  padding-right: 2rem;
  font-size: var(--fs-xl);
  line-height: var(--lh-xl);
  font-weight: var(--fw-semibold);
}
`;