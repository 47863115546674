import { html, TemplateResult, CSSResultGroup } from 'lit';
import { customElement } from 'lit/decorators.js';
import componentStyles from './ochsner-accordion.css.lit';
import { OutlineAccordion } from '../../base/outline-accordion/outline-accordion';

/**
 * The Ochsner Accordion Component
 * @element ochsner-accordion
 * @slot panels: For ochsner-accordion-panels only.
 */
@customElement('ochsner-accordion')
export class OchsnerAccordion extends OutlineAccordion {
  static styles: CSSResultGroup = [OutlineAccordion.styles, componentStyles];

  render(): TemplateResult {
    return html`
      <div
        class="accordion"
        @click=${this.setActive}
        @keydown=${this.handleKeyboardNav}
      >
        <slot name="panels"></slot>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ochsner-accordion': OchsnerAccordion;
  }
}
