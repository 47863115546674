
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
/* TODO: Convert to tailwind */

::slotted(outline-icon) {
  border-radius: 50%;
  padding: 8px;
  transition: background-color 0.3s ease;
}

::slotted(outline-icon:hover) {
  background-color: #cfcfcf;
  transition: background-color 0.3s ease;
}

/* Tooltip container */

.tooltip {
  position: relative;
  display: flex;
  cursor: pointer;
}

/* Tooltip text */

.tooltip .tooltip-text {
  visibility: hidden;
  width: 120px;
  background-color: #646464;
  color: #fff;
  text-align: center;
  padding: 2px;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */

.tooltip .tooltip-text::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #646464 transparent transparent transparent;
  transition: opacity 0.3s;
}

/* Show the tooltip text when you mouse over the tooltip container */

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
`;