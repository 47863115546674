import { r, $ } from '../helpers';

const prefix = 'mm-spn';

/**
 * Class for navigating in a mobile menu.
 */

export default class MmSlidingPanelsNavigation {
  /** Prefix for the class. */
  get prefix() {
    return prefix;
  }

  /** HTML element for the menu. */
  node: HTMLElement;

  /** The title for the menu. */
  title: string;

  /** Whether or not to use sliding submenus. */
  slidingSubmenus: boolean;

  /** The class for selected menu items. */
  selectedClass: string;

  /**
   * Class for navigating in a mobile menu.
   *
   * @param {HTMLElement} node            HTMLElement for the menu.
   * @param {string}      title           The title for the menu.
   * @param {string}      selectedClass   The class for selected listitems.
   * @param {boolean}     slidingSubmenus Whether or not to use sliding submenus.
   * @param {string}      theme           The color scheme for the menu.
   */
  constructor(
    node: HTMLElement,
    title: string,
    selectedClass: string,
    slidingSubmenus: boolean,
    theme: 'light' | 'dark'
  ) {
    this.node = node;
    this.title = title;
    this.slidingSubmenus = slidingSubmenus;
    this.selectedClass = selectedClass;

    //  Add classname.
    this.node.classList.add(prefix);

    this.node.classList.add(`${prefix}--${theme}`);
    this.node.classList.add(
      `${prefix}--${this.slidingSubmenus ? 'navbar' : 'vertical'}`
    );

    this._setSelectedl();
    this._initAnchors();
  }

  /**
   * Open the given panel.
   *
   * @param {HTMLElement} panel Panel to open.
   */
  openPanel(panel: HTMLElement, focusElement: HTMLElement | null = null) {
    /** Parent LI for the panel.  */
    const listitem = panel.parentElement;

    //  Sliding submenus
    if (this.slidingSubmenus) {
      /** Title above the panel to open. */
      let title = panel.dataset.mmSpnTitle;

      //  Opening the main level UL.
      if (listitem === this.node) {
        this.node.classList.add(`${prefix}--main`);
      }

      //  Opening a sub level UL.
      else {
        this.node.classList.remove(`${prefix}--main`);

        //  Find title from parent LI.
        if (!title && listitem && listitem.children.length) {
          r(listitem.children).forEach(child => {
            if (child.matches('a, span, button')) {
              title = child.textContent;
            }
          });
        }
      }

      //  Use the default title.
      if (!title) {
        title = this.title;
      }

      //  Set the title.
      this.node.dataset.mmSpnTitle = title;

      //  Unset all panels from being opened and parent.
      $(`.${prefix}--open`, this.node).forEach(open => {
        open.classList.remove(`${prefix}--open`);
        open.classList.remove(`${prefix}--parent`);
        open.setAttribute('aria-expanded', 'false');
      });

      //  Set the current panel as being opened.
      panel.classList.add(`${prefix}--open`);
      panel.classList.remove(`${prefix}--parent`);
      panel.setAttribute('aria-expanded', 'true');
      setTimeout(() => {
        focusElement?.focus();
      }, 50);

      //  Set all parent panels as being parent.
      let parent: HTMLUListElement | null | undefined =
        panel.parentElement?.closest('ul');
      while (parent) {
        parent.classList.add(`${prefix}--open`);
        parent.classList.add(`${prefix}--parent`);
        parent.setAttribute('aria-expanded', 'true');
        parent = parent.parentElement?.closest('ul');
      }
    }

    //  Vertical submenus
    else {
      /** Whether or not the panel is currently opened. */
      const isOpened = panel.matches(`.${prefix}--open`);

      //  Unset all panels from being opened and parent.
      $(`.${prefix}--open`, this.node).forEach(open => {
        open.classList.remove(`${prefix}--open`);
        open.setAttribute('aria-expanded', 'false');
      });

      //  Toggle the current panel.
      panel.classList[isOpened ? 'remove' : 'add'](`${prefix}--open`);
      panel.setAttribute('aria-expanded', `${isOpened}`);

      //  Set all parent panels as being opened.
      let parent: HTMLUListElement | null | undefined =
        panel.parentElement?.closest('ul');
      while (parent) {
        parent.classList.add(`${prefix}--open`);
        parent.setAttribute('aria-expanded', 'true');
        parent = parent.parentElement?.closest('ul');
      }
    }
  }

  /**
   * Initiate the selected listitem / open the current panel.
   */
  _setSelectedl() {
    /** All selected LIs. */
    const listitems = $('.' + this.selectedClass, this.node);

    /** The last selected LI. */
    const listitem = listitems[listitems.length - 1];

    /** The opened UL. */
    let panel = null;

    if (listitem) {
      panel = listitem.closest('ul');
    }

    if (!panel) {
      panel = this.node.querySelector('ul');
    }
    this.openPanel(panel);
  }

  /**
   * Initialize the click event handlers.
   */
  _initAnchors() {
    /**
     * Click a LI or BUTTON in the menu: open its submenu (if present).
     *
     * @param   {HTMLElement}    target The clicked element.
     * @return  {boolean}               Whether or not the event was handled.
     */
    const openSubmenu = (
      target: HTMLElement,
      focusElement: HTMLElement | null = null
    ): boolean => {
      /** Parent listitem for the submenu.  */
      let listitem: HTMLElement | null = null;

      //  Find the parent listitem.
      if (target.closest('button')) {
        listitem = target.parentElement;
      } else if (target.closest('li')) {
        listitem = target;
      }

      if (listitem && listitem.children.length) {
        // 'a' inside 'li' still assigns to listitem, checking for children now.
        r(listitem.children).forEach(panel => {
          if (panel.matches('ul')) {
            this.openPanel(panel, focusElement);
          }
        });

        return true;
      }
      return false;
    };

    /**
     * Click the menu (the navbar): close the last opened submenu.
     *
     * @param   {HTMLElement}    target The clicked element.
     * @return  {boolean}               Whether or not the event was handled.
     */
    const closeSubmenu = (target: HTMLElement): boolean => {
      /** The opened ULs. */
      const panels = $(`.${prefix}--open`, target);

      /** The last opened UL. */
      const panel = panels[panels.length - 1];
      if (panel) {
        /** The second to last opened UL. */
        const parent = panel.parentElement.closest('ul');
        if (parent) {
          this.openPanel(parent);
          return true;
        }
      }
      return false;
    };

    this.node.addEventListener('click', evnt => {
      const target = evnt.target as HTMLElement;
      // detect keyboard click
      if (evnt.detail === 0) {
        openSubmenu(target, target.parentElement?.querySelector('li > a'));
        closeSubmenu(target);
      } else {
        openSubmenu(target);
        closeSubmenu(target);
      }
    });

    this.node.addEventListener(
      'keydown',
      (evnt: KeyboardEvent) => {
        if (evnt.key === 'Tab') {
          const target = evnt.target as HTMLElement;
          const targetItem = target.closest('li');
          const currentMenu = target.closest('ul');
          const parentMenu = currentMenu?.parentElement?.closest('ul');
          // If shift tab from the first item in submenu open the parent
          if (evnt.shiftKey && targetItem === currentMenu?.firstElementChild) {
            if (parentMenu) {
              this.openPanel(parentMenu, targetItem?.querySelector('button'));
            }
          } else if (
            targetItem === currentMenu?.lastElementChild &&
            !evnt.shiftKey
          ) {
            // Check if the top-level-menu last item is open
            // Opening and closing the menu are handled in the menu component
            const lastMainItemOpen =
              target
                ?.closest('nav>ul')
                ?.lastElementChild?.querySelector('ul')
                ?.getAttribute('class') == 'mm-spn--open';
            // Don't open grandparent if top-level-menu last item is opened
            // Check if the parentMenu-Item opened is the last item in parentMenu
            // if so open the grandParentMenu to follow the focus
            if (
              !lastMainItemOpen &&
              parentMenu?.lastElementChild
                ?.querySelector('ul')
                ?.getAttribute('class') == 'mm-spn--open'
            ) {
              const grandParentMenu = parentMenu?.parentElement?.closest('ul');
              if (grandParentMenu) {
                this.openPanel(grandParentMenu);
              }
            } else if (!lastMainItemOpen && parentMenu) {
              // If `tab` from the last item in the submenu open the parent
              this.openPanel(parentMenu);
            }
          }
        }
      },
      { passive: true }
    );
  }
}
