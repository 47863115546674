
import { css } from 'lit';
export default css`
/* Apply standardized box sizing to the component. */
:host {
  box-sizing: border-box;
}
:host *,
:host *::before,
:host *::after {
  box-sizing: inherit;
}
[hidden] {
  display: none !important;
}
/* Apply component specific CSS */
:host {

    display: block;

    height: 7.5rem;

    width: 7.5rem;

    border-radius: 0.5rem
}

.btn[aria-disabled='true'] {

    pointer-events: none !important;

    cursor: not-allowed !important;

    background-color: var(--demo-lightGray) !important;

    color: var(--demo-darkGray) !important
}

.btn {

    display: flex;

    cursor: pointer;

    flex-direction: column;

    align-items: center;

    border-radius: 0.5rem;

    padding-top: 0.75rem;

    padding-bottom: 0.75rem;

    padding-left: 1.5rem;

    padding-right: 1.5rem;

    text-align: center;

    line-height: 1.5rem;

    text-decoration: none
}

.btn:hover {

    text-decoration: underline
}

.btn:focus {

    text-decoration: underline
}

.btn.campus-map {

    background-image: linear-gradient(to bottom, var(--tw-gradient-stops));

    --tw-gradient-from: var(--brand-blue);

    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to, rgb(255 255 255 / 0));

    --tw-gradient-to: var(--brand-blueDark);

    font-family: Montserrat, Helvetica, Arial, sans-serif;

    color: var(--neutral-white)
}

.btn.campus-map:hover {

    background-color: var(--brand-blueDark)
}

.btn.campus-map:focus {

    background-color: var(--brand-blueDark)
}

.btn-label {

    margin-top: 0.5rem;

    display: block
}
`;